.empty_data_main_container {
    width: 100%;
  .empty_data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 80px;
      height: 80px;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 18px;
      margin-left: -2px;
    }
  }
}
