.change_password_container {
  //   margin-top: 67px;
  //   display: flex;
  //   justify-content: flex-end;
  padding: 30px 50px;

  .change_password_modal_title {
    p {
      font-family: "Poppins", sans-serif;
      color: #262626;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  form {
    margin-top: 30px;
    .password_input_and_lable {
      // margin-bottom: 20px;
      height: 105px;
      // border: 1px solid red;
      label {
        color: #929292;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-left: 3px;
      }
      .password_input {
        position: relative;
        width: 100%;
        // border: 1px solid red;
        margin-top: 5px;

        input {
          width: 100%;
          height: 55px;
          padding: 14px 17px 14px 17px;
          border: 1px solid #000000;
          border-radius: 6px;
          // background-color: #fafafa;
          font-family: "Poppins", sans-serif;
          outline: none;
          &:focus{
            border: 1px solid #0085ff;
          }
        }
        .password_show_and_hide {
          position: absolute;
          cursor: pointer;
          right: 10px;
          top: 17px;
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width:991px) {
    padding: 20px;
    .change_password_modal_title p{font-size: 20px !important;}
    form{margin-top: 10px;.btn_container{margin-top: 15px;}}
  }

  .btn_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
    button {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: black;
      // color: white;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid transparent;
    }

    .cancle_button {
      width: 100px;
      height: 55px;
      background-color: white;
      border: 1px solid black;

      &:hover {
        background-color: rgba(226, 226, 226, 0.7);
        border: 1px solid transparent;
      }
    }
    .submit_button {
      width: 180px;
      height: 55px;
      margin-left: 20px;
      background-color: black;
      color: white;
      &:hover {
        background-color: white;
        color: black;
        border: 1px solid black;
      }
    }
  }
  .error_message {
    margin-bottom: 8px;
    color: red;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-left: 2px;
  }
}

