$primaryColors:rgba(18, 18, 18, 1);
$primaryTransition:0.3s ease;

.main-card{
    .sidebar-mdiv{
        box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 1);
        width: 335px;
        min-width: 334px;
        border-radius: 32px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        @media (max-width:1024px) {
            display: none;
        }
    }
    .sidebar{
        position: relative;
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 335px;
        min-width: 334px;
        border-radius: 32px;
        padding-bottom: 25px;
        &::before{
            width: 80px;
            height: 100%; 
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            background: $primaryColors;
            z-index: 1;
        }
        .sidebar-head{
            display: flex;
            position: relative;
            z-index: 2;
            .left-black-div{
                width: 80px;
                background: $primaryColors;
            }    
            .sidebar-head-detail{
                width: calc(100% - 80px);
                padding: 10px;
                padding-top: 25px;
            
                .sidebar-logo-div{
                    width: 100%; 
                    margin-bottom: 15px;
                    img{
                        width: 100%;
                        object-fit: contain;
                        max-height: 180px;
                    }
                }
                .sidebar-logo-title{
                    font-size: 20px;
                    font-weight: 800;
                    line-height: 30px;
                    text-align: center;      
                    font-family: "Poppins", sans-serif;     
                }
            }
        }
        .sidebar-menu{
            padding: 0;
            padding-right: 25px;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: fit-content;
            .menu-ul{
                list-style: none;
                padding: 0;
                .menu-li{
                    margin-bottom: 5px;
                    &:last-of-type{margin-bottom: 0;}
                }
                .menu-link{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start; 
                    gap: 20px;
                    min-height: 45px;
                    transition: $primaryTransition;
                    .menu-link-name{
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27px;
                        font-family: "Poppins", sans-serif;     
                        color: $primaryColors;
                        transition: $primaryTransition;
                        &:has(hr){
                            width: 100%;
                        }
                    }
                    .menu-link-icon{
                        width: 80px;
                        min-width: 80px;
                        background: $primaryColors;
                        display: inline-flex;
                        align-items: center; 
                        justify-content: center;
                        align-self: stretch;
                        transition: $primaryTransition;
                        svg{
                            width: 30px;
                            height: 30px;
                            object-fit: contain;
                            fill: #fff;
                        }
                        img{
                            width: 30px;
                            height: 30px;
                            object-fit: contain;
                            object-position:center;
                        }
                    }
                    hr{border: 0; border-top: 1px solid rgba(197, 197, 197, 1); width: 100%;}
                    &.active{
                        .menu-link-icon{
                            background: #fff;
                            svg{
                                fill: $primaryColors;
                                height: 28px;
                            }
                            img{
                                filter: invert(1);
                                height: 28px;
                            }
                        }
                        .menu-link-name{
                            color: #999999;
                        }
                    }
                    &:hover{
                        .menu-link-name{
                            color: #999999;
                        }
                    }
                }
            }
        }
        @media (max-width:1024px) {
        display: none;
        }
    }
}
.sidebar-maindiv{
    width: 335px;
    min-width: 335px;
    height: 100%;
    @media (max-width:1024px) {
        display: none;
    }
}

#sidebar{
    will-change: min-height;
}

#sidebar .sidebar__inner{
    position: relative;
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}