.layout {
//   border: 1px solid red;
  padding-top: 85px;

  @media (max-width: 1024px) {
    margin-top: 60px;
    padding-top: 60px;
  }

  @media (max-width: 600px) {
    margin-top: 0px;
    
  }
}
