.loading_with_text_main_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-left: -2px;
    margin-top: 10px;
    margin-left: 5px;
  }
}
