header {
  width: 100%;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0;
  margin: auto;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  &.sidebar-open {
    .sidebar {
      transform: translateX(0);
    }
  }
  .header_mdiv{
    @media (min-width: 1024px) {
      display: flex; 
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  .container {
    max-width: 1920px;
    margin: auto;
    padding: 12px 120px 12px 120px;
    background-color: #fff;
    @media (max-width: 1440px) {
      padding: 12px 50px 12px 50px;
    }
    @media (max-width: 1024px) {
      padding: 8px 15px;
    }

    .login_web {
      min-width: fit-content;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .header_user_login_ {
      position: relative;
      .header_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        margin-right: 3%;
        cursor: pointer;
        @media (max-width: 1024px) {
          display: none;
        }
        .use_image {
          img {
            width: 24px;
            height: 24px;
          }
        }
        .user_name {
          min-width: fit-content;
          p {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .logout_main_box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        .logout_option {
          margin-top: 10px;
          width: 227px;
          border-radius: 10px;
          border: 1px;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          box-shadow: 0px 4px 4px 0px #00000040;
          padding: 15px 15px 15px 20px;
          ul {
            li {
              padding: 5px;
              a {
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #000000;
              }
            }
          }
        }
      }
    }

    .header_container {
      display: flex;
      margin-top: 0px;
      justify-content: center;
      gap: 109px;
      align-items: center;
      width: 100%;
      @media (max-width: 1300px) {
        gap: 30px;
      }

      .header-logo-loader {
        height: 50px !important;
      }

      @media (max-width: 1024px) {
        display: none;
      }

      .logo_cont {
        transition: 0.3s ease;
        img {
          max-width: 134px;
          height: 50px;
          object-fit: contain;
          transition: 0.3s ease;
        }
        &:hover{
          img{
            transform: scale(1.05);
          }
        }
      }
      .header_menu {
        display: flex;
        margin-inline:auto ;
        .pointer_none {
          pointer-events: none;
        }
        ul {
          display: flex;
          gap: 57px;
          @media (max-width: 1600px) {
            gap: 25px;
          }
          @media (max-width: 1300px) {
            gap: 20px;
          }

          li {
            a {
              color: #000;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              &.active {
                color: #0085ff;
              }
              &:hover {
                color: #0085ff;
              }
            }
          }
        }
      }
    }

    .responsive_view {
      display: none;
      @media (max-width: 1024px) {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .menu_burger_icon {
          img {
            width: 28px;
            height: 28px;
          }
        }
        .event_logo_cont {
          img {
            max-width: 150px;
            height: 53px;
            object-fit: contain;
          }
        }
        .user_icon {
          img {
            width: 24px;
            height: 24px;
          }
        }

        .header-logo-loader {
          height: 40px !important;
        }
      }
    }

    @media (max-width: 1024px) {
      .responsive_view {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .menu_burger_icon {
          img {
            width: 28px;
            height: 28px;
          }
        }
        .event_logo_cont {
          img {
            width: 134px;
            height: 30px;
          }
        }
      }
    }
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 315px;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    padding: 20px 15px 20px 15px;
    overflow-y: scroll;

    .sidebar_header {
      display: flex;
      align-items: center;
      gap: 41px;
      .cancel_icon {
        cursor: pointer;
        padding: 10px;
        img {
          width: 21.45px;
          height: 24.96px;
        }
      }
      .event_logo {
        img {
          width: 134px;
          height: 40.28px;
          object-fit: contain;
        }
      }
    }
    .sidebar_menu {
      margin-top: 15px;
      padding: 5px;
      ul {
        li {
          padding-top: 10px;
          padding-bottom: 10px;
          a {
            text-decoration: none;
            color: rgba(0, 0, 0, 1);
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0em;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
          }
          .arrow {
            font-size: 25px;
          }
          ul {
            display: none;
            li {
              padding: 5px 5px 5px 10px;
              // list-style-type: disc;
              // list-style-position: inside;
              a {
                color: rgba(0, 0, 0, 1);
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 36px;
                letter-spacing: 0em;
                display: contents;
                &:hover {
                  color: #0085ff;
                  background-color: #f0f0f0;
                  border-radius: 5px;
                  padding: 0px 10px;
                }
              }
            }
          }
          ul {
            display: block;
          }
        }
      }
    }
  }

  .event-logo-container {
    height: auto;

    img {
      width: auto !important;
      height: auto !important;
      max-height: 50px;
      max-width: 134px !important;
    }
  }
}
.extra-menu-title{font-size: 22px; font-weight: 700; line-height: 33px; margin: 10px 0; display: flex; align-items: center; gap:3px;}
.extra-menu-title-icon{width: 35px; height: 35px;}
.extra-menu-ul{padding-left: 20px;}
.extra-menu-icon{height: 14px; object-fit: contain;}
.sidebar-username-div{
  display: flex; 
  align-items: center; 
  gap: 5px;
  span{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
}