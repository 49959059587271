.main-div {
    display: flex;
    justify-content: center;
    padding: 10px ;
    background: white;
}
.heading-text {
    font: Poppins;
    font-family: Poppins;
    font-weight: 700;
    text-align: center 
}

.table-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center 
}

.arrival-main-div {
    flex: 1 1 40%;
    max-width: 40%;
    margin: 5px;

 .no-of-pax {
    width: 100%;
    background: rgb(228, 228, 228);
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font: Poppins;
    font-family: Poppins;
    font-size: 14px;
    color: black
    }

    .other-pax {
        width: 100%;
        background: white;
        display: flex;
        height: 55px;
        justify-content: space-between;
        padding: 5px;
        font: Poppins;
        font-family: Poppins;
        font-size: 14px;
        color: black
        }

        .airport-from {
            width: 100%;
            background: rgb(228, 228, 228);
            display: flex;
            justify-content: space-between;
            padding: 5px;
            font: Poppins;
            font-family: Poppins;
            font-size: 14px;
            color: black
            }
}
.arrival-div {
     width: 100%;
    background: gray;
    display: flex;
    justify-content: center;
    padding: 5px;
    color: white
}

.departure-main-div {
    flex: 1 1 40%;
    max-width: 40%;
    margin: 5px;
    .no-of-pax {
        width: 100%;
        background: rgb(228, 228, 228);
        display: flex;
        justify-content: space-between;
        padding: 5px;
        font: Poppins;
        font-family: Poppins;
        font-size: 14px;
        color: black
        }
        .other-pax {
            width: 100%;
            background: white;
            display: flex;
            justify-content: space-between;
            padding: 5px;
            font: Poppins;
            font-family: Poppins;
            font-size: 14px;
            color: black
            }
            .airport-from {
                width: 100%;
                background: rgb(228, 228, 228);
                display: flex;
                justify-content: space-between;
                padding: 5px;
                font: Poppins;
                font-family: Poppins;
                font-size: 14px;
                color: black
                }
}
.departure-div {
    width: 100%;
   background: gray;
   display: flex;
   justify-content: center;
   padding: 5px;
   color: white
}

.select-options-div {
    display: flex;
    justify-content: flex-start;
    width: 70%;
    border: 1px solid rgb(202, 196, 196);
    border-radius: 5px;
    padding: 30px;
    margin-top: 20px;
}

.save-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 70%;
}

.paxArrival {
    height: 30px;
}
.arrivalPaxName {
    width: 50%;
}

.error {
    color: red;         
    font-size: 12px;    
    margin-top: 5px;    
  }
  

  
  @media (max-width: 600px) {
    .arrival-main-div, .departure-main-div {
        flex: 1 1 100%;
        max-width: 100%;
    }
    .select-options-div {
        width: 100%;
        padding: 20px;
    }
    .save-button {
        width: 100%;
    }
    .heading-text {
        font-size: 16px;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .arrival-main-div, .departure-main-div {
        flex: 1 1 100%;
        max-width: 100%;
    }
    .select-options-div {
        width: 90%;
        padding: 25px;
    }
    .save-button {
        width: 90%;
    }
}

@media (min-width: 769px) {
    .arrival-main-div, .departure-main-div {
        flex: 1 1 40%;
        max-width: 40%;
    }
    .select-options-div {
        width: 70%;
        padding: 30px;
    }
    .save-button {
        width: 70%;
    }
}