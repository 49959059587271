.footer_main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  background: rgba(0, 0, 0, 0.05);
  .footer_container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 57px 120px 44px 120px;
    @media (max-width: 1440px) {
      padding: 25px 50px 20px 50px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 75px;
      padding-inline: 15px;
      
    }
    @media (max-width: 768px) {
      padding: 20px 15px 20px 15px;
    }
    .footer-hr{
      border-top: 1px solid rgba(163, 163, 163, 1);
      max-width: 1264px;
      margin: 36px auto;
    }
    .copyright-p{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      @media (max-width:576px) {
        font-size: 11px;      
      }
    }
    .footer_row {
      display: flex;
      justify-content: space-between;
      border-radius: 35px;
      background: #f9f9f9;
      padding: 23px 80px 20px 80px;
      @media (max-width: 1440px) {
        padding: 20px 50px 20px 50px;
      }
      @media (max-width: 768px) {
        padding: 38px 28px 38px 28px;
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 40px;
      }
      .footer_part {
        .footer_header {
          p {
            color: #7f71a5;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .sub_text {
          margin-top: 10px;
          ul {
            li {
              color: #000;
              font-family: "Poppins", sans-serif;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .sub_text_resources {
          margin-top: 10px;
          display: flex;
          gap: 100px;
          @media (max-width: 1440px) {
            gap: 50px;
          }
          ul {
            li {
              align-items: center;
              display: flex;
              color: #000;
              font-family: "Poppins", sans-serif;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              .arrow_right {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }
    }
    .footer_bottom {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      text-align: center;
      @media (max-width: 768px) {
        margin-top: 15px;
      }
      p {
        color: #a3a3a3;
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .footer-menu{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 23px;
      .footer-menu-ul{
        display: flex;
        align-items: center;
        gap: 20px;
        .footer-menu-link{
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          color: rgba(0, 0, 0, 1);  
        }
        @media (max-width:786px) {
            flex-wrap: wrap;
            width: 270px;
            gap: 10px;
          .footer-menu-li{
            min-width: 46%;
            width: 46%;
          }
        }  
        @media (max-width:425px) {
          width: 100%;
        }
      }
    }
  }
}
