.hotels-list-conatiner {
  width: 100%;
  margin-top: 30px;
  .hotels-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .hotels-list-item {
      padding: 10px;
      width: 270px;
      min-height: 250px;
      border: 1px solid lightgray;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-right: 10px;

      .hotels-image {
        margin-bottom: 5px;
        img {
          width: 100%;
          height: 180px;
          object-fit: cover;
        }
      }

      .hotels-bottom-info {
        border-top: 1px solid lightgray;

        .name {
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 500;
          color: black;
        }

        .address {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: gray;
        }
      }
    }
  }
}


.new-hotels-list{
  .hotels-list{
    flex-wrap: wrap;
    gap: 26px;
    .hotels-list-link{
      width: calc(33.33% - 18px);
      @media (max-width:1200px) {
        width: calc(50% - 13px); 
      }
      @media (max-width:576px) {
        width: 100%; 
      }
      .hotels-list-item{
        margin: 0;
        padding: 40px 20px;    
        width: 100% !important;  
        height: 100%;
        border-radius: 10px;
        border: 0.5px solid #d3d3d3;
        transition: 0.3s ease;
        &:hover{
          border: 1px solid rgba(0, 179, 255, 1);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        }
        .hotels-image{
          margin-bottom: 65px;
          img{
            width: 100%;
            height: 175px;
            max-height: 175px;
            min-height: 175px;
            border-radius: 6px;
          }
        }
      }
      .hotels-bottom-info{
        border: 0;
        text-align: center;
        .name{
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          margin-bottom: 30px;
        }
        .address{
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: rgba(39, 39, 39, 1);
        }
      }
    }
  }
}