.event_box_header_container {
  width: 100%;
  margin: 0 auto;

  .event_box_banner_container {
    padding: 0;
    .row {
      width: 100%;
      .card_contain {
        position: relative;
        width: 100%;
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:1024px) {
          // padding-top: 115px;
        }
        &::before{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background: rgba(0, 0, 0, 0.2);
          z-index: 2;
          border-radius: 15px 15px 0 0;
        }
        .card_logo_div{
          position: absolute;
          top: -88px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 100%;
          background: #fff;
          height: 176px;
          width: 176px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 5;
          display: none;
          overflow: hidden;
          .card_logo{
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
          @media (max-width:1024px) {
            display: flex;
          }
        }
        .aviation_image {
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border-radius: 15px 15px 0px 0px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%; height: 100%;
          z-index: 1;
          img {
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .card {
          position: static;
          z-index: 3;
          background: transparent;
          display: flex;
          flex-direction: column;
          padding: 0;
          width: 100%;
          max-width: 100%;
          padding-inline: 10px;
          z-index: 2;
          .card_title {
            p {
              text-align: center;
              color: #fff;
              text-shadow: none;
              font-family: "Poppins", sans-serif;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              @media (max-width: 425px) {
                font-size: 30px;
              }
            }
          }
          @media (max-width:1024px) {
            padding-top: 84px;
            padding-bottom: 44px;

          }
        }
      }
    }
  }
}
.event_box_location_detail{
  display: grid;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 15px 15px;
  display: flex; 
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  padding: 12px;
  background: #fff;
  .event_box_location_item{
    @media (min-width:1300px) {
      max-width: 31%;      
    }
    .event_box_location_content{
        font-size: 16px;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 5px;
        &.event-date,&.event-time{
          font-weight: 700;
        }
        @media (max-width:1500px) {
          font-size: 14px;
        }
        svg{
          min-width: 20px;
          width: 20px;
          min-height: 20px;
          height: 20px;
        }
      }
  }
  @media (max-width:1300px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    .event_box_location_item{
      width: 48%;
      &:has(.event-time){width: 100%;}
    }
  }
  @media (max-width:576px) {
    gap: 5px;
      max-width: auto;
    .event_box_location_item{
      width: 100%;
      max-width: auto;
    }
  }
}