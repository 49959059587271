$primaryColors:rgba(18, 18, 18, 1);
$primaryFont:"Poppins", sans-serif;
$primaryTransition:0.3s ease;
.conference_nav_bar_main {
  .nav_main {
    position: relative;

    .preview {
      position: absolute;
      left: 0px;
      z-index: 999;
      width: 40px;
      height: 100%;
      background: linear-gradient(
        90deg,
        #ffffff 36.552301%,
        #ffffff 38.552655%,
        rgba(255, 255, 255, 0.00995) 100.323677%
      );
      .preview_circle {
        width: 25px;
        height: 25px;
        background-color: lightgray;
        border-radius: 50%;
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @media (max-width: 768px) {
          margin-top: 10px;
        }
        &:hover {
          background-color: black;
          svg {
            color: white;
          }
        }
      }
    }
    .next {
      // border: 1px solid red;
      position: absolute;
      right: 0px;
      z-index: 999;
      width: 40px;
      height: 100%;
      background: linear-gradient(
        -90deg,
        #ffffff 36.552301%,
        #ffffff 38.552655%,
        rgba(255, 255, 255, 0.00995) 100.323677%
      );
      .next_circle {
        cursor: pointer;
        margin-left: 11px;
        width: 25px;
        height: 25px;
        background-color: lightgray;
        border-radius: 50%;
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          margin-top: 10px;
        }
        &:hover {
          background-color: black;
          svg {
            color: white;
          }
        }
      }
    }
  }
  .event_schedule_main_contain {
    width: 100%;
    display: flex;
    gap: 19px;
    margin-top: 16px;
    overflow: auto;
    // border: 1px solid red;
    scroll-behavior: smooth;
    position: relative;
    &::-webkit-scrollbar {
      display: none !important;
    }

    // @media (max-width: 768px) {
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 11px;
    // }

    .nav_bar_link {
      list-style: none;
      text-decoration: none;
      color: black;
    }
    .date_and_time_schedule {
      min-width: 270px;
      height: 57px;
      border-radius: 32px 32px 0px 0px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      color: black;
      @media (max-width: 768px) {
        // border-radius: 36px;
        border-radius: 20px 20px 0px 0px;
        // min-width: 160px;
        height: 50px;
      }
      p {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .active_date_and_time_schedule {
      background: black !important;
      color: white !important;
    }
  }
  .bottom_line {
    width: 100%;
    border: 1px solid #e9e9e9;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.display_none {
  display: none !important;
}

.container.main-card{
    // @media (min-width:576px) {
    //     max-width: 540px;
    //     width: 100%;
    // }
    // @media (min-width:768px) {
    //     max-width: 720px;
    //     width: 100%;
    // }
    // @media (min-width:992px) {
    //     max-width: 960px;
    //     width: 100%;
    // }
    // @media (min-width:1200px) {
    //     max-width: 1150px;
    //     width: 100%;
    // }
    // @media (min-width:1400px) {
    //     max-width: 1350px;
    //     width: 100%;
    // }
    // @media (min-width:1600px) {
    //     max-width: 1350px;
    //     width: 100%;
    // }
    
    width: 100%;
    max-width: 100%;
    // padding-inline: 10px;
    margin: 0 auto;
    padding-inline: 120px;
    
    max-width: 1920px;
    margin: 0 auto;
    @media (max-width:1440px) {
        padding-inline: 50px;
    }
    @media (max-width:1024px) {
        padding-inline: 15px;
    }
}

.layout{
    padding-top: 81px;
    @media (max-width:1024px) {
        padding-top: 50px;
        margin-top: 0;
    }
}

.main-div{
    background: rgba(245, 245, 245, 0.93);
    padding-top: 30px;
    padding-bottom: 50px;
    width: 100%; 
    &:has(.event_box_header_container){
      @media (max-width:1024px) {
        padding-top: 107px;
      }
    }
    @media (max-width:1024px) {
      padding-top: 0;
    }
    // .sidebar{
    //     box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
    //     background: rgba(255, 255, 255, 1);
    //     width: 335px;
    //     min-width: 334px;
    //     border-radius: 32px;
    //     overflow: hidden;
    //     padding-bottom: 25px;
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     &::before{
    //         width: 80px;
    //         height: 100%; 
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         content: '';
    //         background: $primaryColors;
    //         z-index: 1;
    //     }
    //     .sidebar-head{
    //         display: flex;
    //         position: relative;
    //         z-index: 2;
    //         .left-black-div{
    //             width: 80px;
    //             background: $primaryColors;
    //         }    
    //         .sidebar-head-detail{
    //             width: calc(100% - 80px);
    //             padding: 10px;
    //             padding-top: 25px;
            
    //             .sidebar-logo-div{
    //                 width: 100%; 
    //                 margin-bottom: 15px;
    //                 img{
    //                     width: 100%;
    //                     object-fit: contain;
    //                     max-height: 180px;
    //                 }
    //             }
    //             .sidebar-logo-title{
    //                 font-size: 20px;
    //                 font-weight: 800;
    //                 line-height: 30px;
    //                 text-align: center; 
    //                 font-family: $primaryFont;     
    //             }
    //         }
    //     }
    //     .sidebar-menu{
    //         padding: 0;
    //         padding-right: 25px;
    //         position: relative;
    //         z-index: 2;
    //         display: flex;
    //         justify-content: space-between;
    //         flex-direction: column;
    //         height: 100%;
    //         .menu-ul{
    //             list-style: none;
    //             padding: 0;
    //             .menu-li{
    //                 margin-bottom: 10px;
    //                 &:last-of-type{margin-bottom: 0;}
    //             }
    //             .menu-link{
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: flex-start; 
    //                 gap: 20px;
    //                 min-height: 45px;
    //                 .menu-link-name{
    //                     font-size: 18px;
    //                     font-weight: 500;
    //                     line-height: 27px;
    //                     font-family: $primaryFont;
    //                     color: $primaryColors;
    //                     &:has(hr){
    //                         width: 100%;
    //                     }
    //                 }
    //                 .menu-link-icon{
    //                     width: 80px;
    //                     min-width: 80px;
    //                     background: $primaryColors;
    //                     display: inline-flex;
    //                     align-items: center; 
    //                     justify-content: center;
    //                     align-self: stretch;
    //                     svg{
    //                         width: 30px;
    //                         height: 30px;
    //                         object-fit: contain;
    //                         fill: #fff;
    //                     }
    //                     img{
    //                         width: 30px;
    //                         height: 30px;
    //                         object-fit: contain;
    //                         object-position: center;
    //                     }
    //                 }
    //                 hr{border: 0; border-top: 1px solid rgba(197, 197, 197, 1); width: 100%;}
    //                 &.active{
    //                     .menu-link-icon{
    //                         background: #fff;
    //                         svg{
    //                             fill: $primaryColors;
    //                             height: 28px;
    //                         }
    //                         img{
    //                             filter: invert(1);
    //                             height: 28px;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     @media (max-width:1024px) {
    //     display: none;
    //     }
    // }
}
.reply-button {
  color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
    width: 100px;
    height: 40px;
    margin-top: 30px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    cursor: pointer !important;
  background-color: #bfb8b8;
  padding: 14px 32px;
  &:hover {
    background-color: rgb(52, 51, 51) !important;
    color: white;
  }
}
.mt-auto{margin-top: auto;}
.main-card{
    display: flex; align-items: stretch; justify-content: start; gap: 30px;
}
.main-content-card {
    width: calc(100% - 365px);
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    background: #fff;
    padding: 27px 34px;   
    min-height: calc(100dvh - 116px);
    .event-baner-mdiv{
        min-height: calc(100dvh - 116px);
        height: 100%;
        display: flex; flex-direction: column;
    }
    .event-page-title-mdiv{
        display: flex;
        margin: 25px 0;
        gap: 10px;
        flex-wrap: wrap;
        .event-page-title-time{
            color: #959595;
            font-size: 16px;
            font-family: $primaryFont;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 29px;
            text-align: left;
            vertical-align: bottom;
            margin-top: auto;
            margin-bottom: 4px;
        }
        .event-page-title{
          margin: 0;
        }

    }
    .event-page-title{
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        font-family: $primaryFont;
        margin: 25px 0;
        @media (max-width:992px) {
          font-size: 26px;
          line-height: 30px;
          margin: 15px 0;
        }
        @media (max-width:576px) {
            font-size: 22px;
            line-height: 28px;
        }
    }
    @media (max-width:1024px) {
     width: 100%;
    }
    @media (max-width:1024px) {
        padding: 0;
        background: transparent;
        box-shadow: none;

        .event-baner-mobile-section{
            box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            padding: 20px;
            background: #fff;
            padding-bottom: 30px;
            margin-top: 26px;
            @media (max-width:768px) {
              .location_detail_mdiv{display: none;}
            }
            @media (max-width:576px) {
                padding: 15px;
                padding-bottom: 30px;
                .location_detail_mdiv{display: none;}
            }
        }
        .event-option-section{margin-top: 0;}
    }
}
.event-baner-mobile-section{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.event-option-section{
    margin-top: 45px;
    .event-option-row{
        display: flex; 
        align-items: center;
        flex-wrap: wrap;
        row-gap: 30px;
        margin-inline: -15px;
        @media (max-width:1024px) {
            margin-inline: -8px;
        }
        .event-option-col{
            width: 25%;
            padding: 0 15px;
            @media (max-width:1400px) {
                width: 33.33%;
            }
            @media (max-width:1024px) {
                width: 50%;
                padding: 0 8px;
            }
            .event-option-item{
                background: rgba(245, 245, 245, 1);
                padding: 5px 5px 15px 5px;
                border-radius: 20px;
                overflow: hidden;
                display: block;
                transition: $primaryTransition;
                @media (max-width:576px) {
                    padding: 5px 5px 10px 5px;
                }
                .event-option-img-div{
                    width: 100%;
                    height: 185px;
                    border-radius: 18px 18px 0px 0px;
                    overflow: hidden;
                    margin-bottom: 15px;
                    transition: $primaryTransition;
                    @media (max-width:576px) {
                        margin-bottom: 10px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: $primaryTransition;
                    }
                    @media (max-width:576px) {
                        height: 120px;
                    }
                }
                .event-option-titel{
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    font-family: $primaryFont;
                    text-align: center;
                    color: $primaryColors;
                    transition: $primaryTransition;
                    @media (max-width:576px) {
                        font-size: 10px;
                        line-height: 15px;
                    }
                }
                &:hover{
                  background: #E5E5E5;
                  .event-option-img-div{
                    img{transform: scale(1.1);}
                  }
                }
            }
        }
    }
}
.event-sponsors-section{
    width: 100%;
    margin-top: auto;
    .event-sponsors-title-div{
        width: 100%; 
        position: relative;
        width: 100%;
        display: flex; align-items: center; justify-content: center;
        margin-top: 40px;
        &::before{
            width: 100%;
            height: 1px;
            background: rgba(163, 163, 163, 1);
            content: '';
            top: 50%;
            left: 0;
            position: absolute;
            transform: translateY(-50%);
        }
        .event-sponsors-titel{
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            text-align: center;
            font-family: $primaryFont;
            padding: 5px;
            background: #fff;
            width: fit-content;
            position: relative;
            z-index: 2;
        }
    }
    .sponsors-slider-div{
        position: relative;
        margin-top: 15px;
        .disable_link{
          pointer-events: none !important;
        }
        .sponsors-slider-item{
            border-radius: 10px;
            height: 120px;
            overflow: hidden;
            background: #fff;
            padding: 10px;
            img, svg{
                height: 100%;
                width: 100%;
                object-fit: contain;
                border-radius: 10px;
            }
            @media (max-width:576px) {
                height: 150px;
            }
        }
        @media (max-width:576px) {
            padding-inline: 40px;
        }
    }
    .slider-sponsors-div{
      .swiper-wrapper{
        justify-content: center;
      }
    }
    .button-container {
        button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          font-family: $primaryFont;
          color: #525252;
          background-color: #ffffff;
          border: none;
          border-radius: 50%;
          width: 33px;
          height: 33px;
          cursor: pointer;
          z-index: 90;
        }
        .sub_icon {
            margin-top: 3px;
            height: 24px;
            width: 24px;
          }
        .prev {
          left: -16px;
          @media (max-width:576px) {
            left: 0;
          }
        }
        .next {
          right: -16px;
          @media (max-width:576px) {
            right: 0;
          }
        }
      }
}


.fixed-menu-div{
    display: none;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    bottom: -1px;
    left: 0;
    z-index: 91;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 10px;
    justify-content: space-around;
    .fixed-menu-link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        svg{
            height: 30px;
            width: 30px;
            fill: $primaryColors;
            stroke: $primaryColors;
        }
        img{
            width: 30px;
            height: 30px;
            object-fit: contain;
            filter: invert(1);
        }
        .fixed-menu-name{
            font-size: 11px;
            font-family: $primaryFont;
            font-weight: 500;
            line-height: 16.5px;
            text-align: center;
            color: $primaryColors;
        }
    }
    @media (max-width:1024px) {
        display: flex;
    }
}

.location_detail_title{
    font-size: 20px;
    font-family: $primaryFont;
    font-weight: 700;
    line-height: 30px;
    color: $primaryColors;
    margin-bottom: 8px;
}

.location_detail_mdiv{
    background: rgba(238, 238, 238, 1);
    border-radius: 15px;
    padding: 15px 30px;
    @media (max-width:576px) {
        padding:  10px 15px;
    }
    .event_box_location_detail{
        box-shadow: none !important;
        background: transparent !important;
        justify-content: space-between !important;
        padding: 0 !important;
    }
}
.meetings_management_content {
    // display: flex;
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 34px;
    // width: 100%;
    margin-top: 52px;
    overflow: auto;
    @media (max-width: 1024px) {
      margin-top: 30px;
    }
    @media (max-width:340px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
    .container_box {
      border-radius: 7px;
      border: 1px solid #000000;
      padding: 45px 45px 45px 45px;
      @media (max-width: 425px) {
        padding: 40px 38px 40px 38px;
      }
      .content_icon {
        img {
          width: 71.27px;
          height: 71.27px;
        }
      }
      .title_content {
        margin-top: 27px;
        p {
          font-family: $primaryFont;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(0, 0, 0, 1);
          // white-space: pre-wrap;
          display: block;
        }
      }
    }
  }

  .vanue_map_body_container {
    margin-top: 50px;
  
    .download_btn {
      display: flex;
      justify-content: flex-end;
  
      button {
        width: 183px;
        height: 47px;
        border-radius: 5px;
        background-color: black;
        color: white;
        border: black;
        border: none;
        font-weight: 700;
        font-family: $primaryFont;
        cursor: pointer;
  
        &:hover {
          background-color: rgb(71, 71, 71);
          color: white;
        }
      }
    }
  
    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 768px) {
        justify-content: center;
      }
  
      .download-button {
        width: 183px;
        height: 47px;
        // border: none;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid black;
        color: black;
        font-weight: 700;
        font-family: $primaryFont;
        cursor: pointer;
        margin-right: 20px;
      }
    }
  
    .vanue_map_show_container {
      margin-top: 25px;
      .image_container {
        .aviation_image {
          img {
            background-repeat: no-repeat;
            width: 100%;
            height: 50vh;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }
  
      .document_container {
        .document_contain {
          width: 100%;
          height: 100px;
          height: 100px;
          border-radius: 10px;
          border: 1px solid black;
          background-color: white;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          font-family: $primaryFont;
        }
      }
    }
  }

  .bottom_content {
    margin-top: 71px;
    .bottom_content_header {
      p {
        color: #000000;
        font-family: $primaryFont;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .bottom_line {
      width: 527px;
      border: 1px solid #d9d9d9;
      margin-top: 10px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .details_info {
      cursor: pointer;
      ul {
        padding: 0px 20px 20px 20px;
        margin-top: 16px;
        li {
          color: rgba(0, 0, 0, 1);
          list-style-type: disc;
          font-family: $primaryFont;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          text-decoration: underline;
        }
      }
    }
  }

  .sponsors_details_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    @media (max-width: 1440px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .sponsors_list_image {
      display: grid;
      gap: 50px;
      justify-content: center;
      align-items: center;
      margin-top: 96px;
      @media (max-width: 710px) {
        margin-top: 40px;
        gap: 30px;
      }
      .disable_link{
        pointer-events: none !important;
      }
      .sponsors_image {
        img {
          max-width: 100%;
          max-height: 200px;
          height: auto;
        }

        .profile_demo_logo {
          svg {
            width: 200px;
            height: 200px;
            color: lightgray !important;
          }
        }
      }
      .event_image_name {
        margin-top: auto;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        p {
          font-family: $primaryFont;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: #000000;
        }
      }
    }
  }

  .faqs_container {
    padding-top: 55px;
  
    .item {
      margin-bottom: 10px;
      .question_container {
        width: 100%;
        border-radius: 16px;
        background-color: #f9f9f9;
        padding: 10px 10px 10px 30px;
        min-height: 70px;
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 40px;
        p {
          font-family: $primaryFont;
          font-weight: 500;
          color: #262626;
        }
        .icon_container {
          position: absolute;
          right: 20px;
          font-size: 20px;
          font-family: $primaryFont;
          color: #646464;
        }
      }
  
      .answer_container {
        padding: 10px 10px 10px 30px;
        margin-top: 12px;
        margin-bottom: 20px;
        cursor: auto;
        li {
          color: #262626;
          // list-style-type: circle;
          list-style: disc;
        }
      }
    }
  }
  
  .arrow_position_active {
    transform: rotate(90deg) !important;
  }

  .main-content-card {
    .bottom_content {
      .bottom_line{width: 100%;}
    }
  } 

  .sponsored_details_main_content {
    margin-top: 54px;
    .sponsored_header {
      p {
        color: #000000;
        font-family: $primaryFont;
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .sponsor_logo_content {
      display: flex;
      gap: 17px;
      overflow-x: auto;
      margin-top: 30px;

      @media (max-width: 768px) {
        justify-content: center;
        gap: 10px;
        max-height: 200px;
        overflow-x: auto;
      }
      .disable_link {
        pointer-events: none !important;
      }
      .sponsor_logo {
        img {
          width: 100px;
          height: 100px;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .common_textarea_contain {
    margin-top: 13px;
    label {
      font-family: $primaryFont;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #262626;
      margin-bottom: 4px;
    }
    .full_width_textarea {
      box-sizing: border-box;
      border: 1px solid #000000;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
      font-family: $primaryFont;
    }

    .container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 20px;

      label {
        display: flex;
        flex-direction: column;
        width: 50%;
        font-family: $primaryFont;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #262626;
        margin-bottom: 4px;
        input {
          flex: 1;
          padding: 15px 10px;
          border: 1px solid #000000;
          border-radius: 8px;
        }
      }
    }
  }
  .common_save_button {
    margin-top: 13px;
    display: flex;
    justify-content: end;
    button {
      background-color: #000000;
      border: none;
      border-radius: 8px;
      color: white;
      text-align: center;
      text-decoration: none;
      // display: inline-block;
      font-size: 16px;
      font-weight: 700;
      // padding: 8px 48px;
      width: 120px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  
.newsponsors_details_container{display: flex; align-items: stretch;flex-wrap: wrap; gap: 26px;}
.newsponsors_item{
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 18px); 
  border: 1px solid #d3d3d3; 
  padding: 25px 35px;
  border-radius: 10px;
  transition: 0.3s ease;
  &:hover{
    border: 1px solid rgba(0, 179, 255, 1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  @media (max-width:1200px) {
    width: calc(50% - 13px); 
  }
  @media (max-width:576px) {
    width: 100%; 
  }
  .newsponsors_img_div{
    height: 235px;
    margin-bottom: 18px;
    .newsponsors_img{width: 100%; height: 100%; object-fit: contain;}
  }
  .newsponsors_title{
    font-family: $primaryFont;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-bottom: 0;
  }
  .profile_demo_logo {
    svg {
      width: 100%;
      height: 235px;
      color: lightgray !important;
      margin-bottom: 18px;
    }
  }
}




.conference_info_main_contain .user_details_contain .user_details_menu .user_data_manager,.responsibilities_info_main_contain .user_details_contain .user_responsibilities_info .user_details_menu .user_data_manager {
  @media (max-width: 1100px) {
    display: flex !important;
    gap: 30px !important;
    flex-direction: row !important;
    align-items: center !important;
  }
}

.event_program_info_readless{max-height: 200px; overflow: hidden; width: 200px;}
.event_program_info_readless.readmore{max-height: fit-content !important;}
.readless_btn{
border: none;
    text-align: center;
    font-size: 16px;
    background: transparent;
    cursor: pointer;
    border: none;
    text-align: center;
    background: transparent;
    text-decoration: underline;
    color: #262626;
    cursor: pointer;
    font-size: 13px;
}
.invite_other_companies_btn{
  border: 1.28px solid rgba(204, 204, 204, 1); 
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
  gap: 10px;
  font-family: $primaryFont;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.81px;
  text-align: left;
  cursor: pointer;
  transition: 0.3s ease;
  margin-top: 30px;
  &:hover{
    background: #000000; 
    color: #fff;
  }
  @media (max-width:576px) {
    font-size: 16px;
  }
}

.request_meeting_modal {
  .modal-content {
    .company-select-col{
      margin-top: 100px;
      @media (max-width:576px) {
        margin-top: 50px;
      }
      .profile_data_mdiv{
        display: flex;
        align-items: center;
        margin-top: 30px;
        gap: 16px;
        @media (max-width:576px) {
          gap: 8px;
          margin-top: 15px;
        }
        .profile_data{
          gap: 25px;
          margin-top:0 ;
          @media (max-width:576px) {
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 10px;
            .user_name{
              p{
                text-align: left !important;
                font-size: 16px;
                line-height: 20px;
              }
            }
            .event_name p{
              text-align: left !important;
              font-size: 14px;
              line-height: 20px;

            }
            .user_data{ 
              p{
                text-align: left !important;
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
          
          .event_name p{
            font-weight: 700;
          }
        }
        .checkbox{
          margin-top: 0;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          border: 2px solid rgb(144, 144, 144);
          background: linear-gradient(0deg, #919191, #919191), linear-gradient(0deg, #ffffff, #ffffff);
          accent-color: #000;
        }
      }
    }
  }
}
.profile_data_icon{
  height: 95px; 
  width: 95px; 
  min-width: 95px; 
  border-radius: 1000px; 
  overflow: hidden;
  @media (max-width:576px) {
    height: 45px; 
    width: 45px; 
    min-width: 45px; 
  }
  img{
    width: 100%; 
    height: 100%; 
    object-fit: contain;
  }
}
.company-select-title-div{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  @media (max-width:576px) {
    gap: 10px;
  }
  .company-select-name{
    font-family: $primaryFont;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    @media (max-width:576px) {
      font-size: 20px;
    }
  }
  .company-select-remove-link{
    font-family: $primaryFont;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgba(38, 38, 38, 1);
    display: flex;
    align-items: center;
    cursor: pointer;
    svg{
      height: 20px;
      width: 20px;
    }
    }
}
.company-searchresult-input{
  width: 100%;
  height: 63px;
  border: 1px solid rgba(235, 235, 235, 1);
  padding: 15px 30px;
  font-family: $primaryFont;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  &::placeholder{
    font-family: $primaryFont;
    font-style: italic;
    color: rgba(89, 89, 89, 1);
  }
  @media (max-width:576px) {
    font-size: 16px;
  }
}
.company-searchresult-mdiv{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  padding: 30px 0 0 0;
}
.company-searchresult-inputdiv{
  margin-top: 60px;
  .label_text{
    font-family: $primaryFont;
    color: #595959;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 6px;
    @media (max-width:576px) {
      font-size: 16px !important;
    }
  }
  @media (max-width:576px) {
    margin-top: 30px;
  }
}
.company-searchresult-div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  
  .company-searchresult-icon{
    width: 33px;
    min-width: 33px;
    height: 33px;
    background: rgba(217, 217, 217, 1);
    border-radius: 100px;
    overflow: hidden;
    img{
      width: 100%; height: 100%;object-fit: cover;
    }
  }
  .company-searchresult-title{
    font-family: $primaryFont;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: rgba(89, 89, 89, 1);
    @media (max-width:576px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .company-searchresult-desc{
    font-family: $primaryFont;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(89, 89, 89, 1);
  }
}


.availale_for_meeting{
  font-family: $primaryFont;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: rgba(92, 232, 106, 1);
  margin-top: 30px;
}

.end_time_error{
  font-family: $primaryFont;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin-top: 30px !important;
}
.status_tab_table{
  padding: 3px 13px;
  gap: 10px;
  border-radius: 4px;
  background: rgba(234, 234, 234, 1);
  font-family: $primaryFont;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: rgba(24, 24, 24, 1);
  margin-left: 10px;
  min-width: fit-content;
  display: inline-block;
  text-transform: capitalize;
  &.declined{
    background:rgba(232, 162, 162, 1) ;
  }
  &.accepted{
    background:rgba(132, 215, 170, 1) ;
  }
  &.pending{
    background: rgb(66, 138, 245) ;
    color: #fff;
  }
}
.meeting_name_contain{margin-bottom: 10px;}
.company_name{
  &:has(.status_tab_table){
    white-space: wrap;
    word-break: break-all;
    word-break: break-word;
  }
}
.decline_btn{
  background: #e8a2a2 !important;
  &:hover{background: red !important;}
}
.location-detail-div{
  .location-detail{
    font-family: $primaryFont;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: rgba(24, 24, 24, 1);
  }
}

.company-list-mdiv{
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
  margin-top: 35px;
  .company-list-div{
    .company-list-title{
      font-family: $primaryFont;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(24, 24, 24, 1);
      margin-bottom: 8px;
    }
    .company-list-name{
      font-family: $primaryFont;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: rgba(24, 24, 24, 1);
      margin-bottom: 6px;
    }
  }
  @media (max-width:768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)); 
  }
  @media (max-width:576px) {
    grid-template-columns: repeat(1, minmax(0, 1fr)); 
  }
}
.decline_modal_title{
  margin-bottom: 17px;
}

.decline-meeting-subtitle-mdiv{
  .decline-meeting-subtitle{
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(38, 38, 38, 1);
    margin-bottom: 5px;
  }
  .decline-meeting-desc{
    font-family: $primaryFont;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(38, 38, 38, 1);
    margin-bottom: 0;
  }
}

.meetings-note-mdiv{
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
  gap: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  img{
    width: 20px;
    height: 20px;
    min-width: 20px;
    object-fit: contain;
  }
  .meetings-note-desc{
    font-family: $primaryFont;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(125, 143, 154, 1);
    }
}



.mobile_conference_nav_bar_main{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 58px;
  margin-top: 40px;
  .conference_nav_bar_link{
    width: 100%;
    border-radius: 32px;
    padding: 17px;
    background: rgba(245, 245, 245, 1);
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(136, 136, 136, 1);
    transition: 0.3s ease;
    &:hover, &.active_date_and_time_schedule{
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.11);
      font-weight: 700;
      color: rgba(0, 0, 0, 1);
      background: rgba(255, 255, 255, 1);
    }
  }
}
.user_data_detail_link{
  color: rgba(0, 0, 0, 0.87);
}

.mobile-main-contain{
  .mobile-table-detail-head{
    padding: 20px;
    text-align: center;
    border-block: 1px solid rgba(0, 0, 0, 1);
  }
  .event_program_info_readless{
    width: 100%;
    max-height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    &.readmore{
      display: block;
    }
  }
  .event_program_info_readless.event_program_info_readless70{max-height: 74px;}
  .mobile-table-detail-body{
    margin-top: 37px;
    @media (max-width:425px) {
      margin-top: 25px;
    }
  }
  .location{
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 20px;
    color: rgba(38, 38, 38, 1);
  }
  .common-p{
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(38, 38, 38, 1);
    margin-bottom: 6px;
  }
  .name{
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .event_program_info{
    p{
      font-family: $primaryFont;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;  
      
    }
  .company_name{font-weight: 700;}
  }
  .comments{margin-top: 15px; font-style: italic;}
  .mobile-table-detail-item{
    margin-bottom: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    &:last-of-type{border: 0;}
    padding-block: 20px;
  }
  .button_contain_conference_table{margin-top: 28px;}
  .sponsored_info{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    .sponsored_info_logo_container{
      width: 50px;
      max-height: 50px;
      display: flex;
      align-items: center;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
      }
      svg{
        width: 30px;
        height: 30px;
      }
    }
  }

  .meeting_names_container{
    .meeting_name_contain{
      margin-bottom: 0;
      .company_name{margin-bottom: 10px;}
      .name{margin-bottom: 10px; margin-top: 0;}
    }
  }
  .schedule_button_contain {margin-top: 20px;}
  .request-accept-meetings-mdiv{
    .comments:first-of-type{margin-top: 0;}
    .comments{margin-bottom: 10px;}
  }
  .others_button_contain,.networking_event_butons_button_contain {margin-top:20px;}
  .event-name{margin-bottom: 15px;}
  .schedule_cell{
    margin-top: 20px;
    .attending_schedule_cell{width: fit-content;}
    button{
      border: none;
      text-align: center;
      background: transparent;
      // margin-left: 12px;
      text-decoration: underline;
      color: #262626;
      cursor: pointer;
    }
  }
  .button_contain{margin-top: 20px;}
}


.push-notifications-mdiv{
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 37px;
  margin-bottom: 27px;
  .push-notifications-div{
    padding: 8px 35px;
    border-radius: 6px;
    background-color: rgba(200, 239, 255, 1);
    width: fit-content;
    &.push-notifications-div2{
      background-color: rgba(255, 240, 200, 1);
    }
    .push-notifications-content{
      font-family: $primaryFont;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: $primaryColors;
      .enable_notification_span{
        color: $primaryColors;
        text-decoration: underline;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover{text-decoration: none;}
      }
    }
  }
}

.view-schedule-list-mdiv,.networking-events-list-mdiv{
  .push-notifications-mdiv{
    @media (min-width:768px) {
      margin-bottom: 0;
      margin-top: 15px;
    }
  }
}
.event-baner-mobile-section{
  &:has(.mobile_conference_nav_bar_main){
    .push-notifications-mdiv{
      margin-top: 0;
    }
  }
}

.location_select_container {
  .label_text {
    font-family: "Poppins", sans-serif;
    color: #595959;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 6px;
  }

  .select_box {
    position: relative;
    .select_box_circular_progress {
      background-color: white;
      z-index: 999;
      position: absolute;
      right: 13px;
      top: 16px;
    }
  }

  .error_para {
    font-family: "Poppins", sans-serif;
    color: red;
    font-size: 12px;
    margin-top: 3px;
  }
}

.button_contain {
  overflow: visible !important;
  button {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    padding: 10px 12px !important;
    transition: 0.3s ease !important;
  }
}

button{
  &.accept {
    background-color: #84d7aa;
    &:hover {
      background-color: green !important;
      color: white;
    }
  }
  &.reschedule {
    background-color: #eed0a3;
    // padding: 14px 19px;
    &:hover {
      background-color: orange !important;
      color: black;
    }
  }

  &.decline {
    background-color: #e8a2a2;
    // padding: 14px 32px;
    &:hover {
      background-color: red !important;
      color: white;
    }
  }
  &.sendMessage {
    background-color: #bfb8b8;
    // padding: 14px 32px;
    &:hover {
      background-color: rgb(52, 51, 51) !important;
      color: white;
    }
  }
}
.mt-md-10{
  @media (min-width:768px) {
    margin-top: 0;
  }
  margin-top: 10px;
}
.common-add-btn{
  border: 1px solid rgba(204, 204, 204, 1) !important;
  background: rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none !important;
  transition: 0.3s ease;
  min-width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  &:hover{
    background: rgba(18, 18, 18, 1) !important;
    color: #fff !important;
    .icon{fill: #fff;}
  }
  .icon{margin: 0 !important;}
  span{
    text-decoration: none !important;
    word-break:normal;
  }
}