.header_user_login_ {
  position: relative;
  // @media (max-width: 1024px) {
  //   display: none;
  // }

  .user_data_container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .user_data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    border: 2px solid rgba(218, 218, 218, 1);
    padding: 10px 20px;
    border-radius: 10px;
    transition: 0.3s ease;
    &:hover{
      background: rgb(245, 245, 245);
    }
    @media (max-width:1200px) {
      padding: 0;
      border: 0;
    }
    // border: 1px solid red;
    // @media (max-width: 768px) {
    //   display: none;
    // }
    cursor: pointer;
    .user_image {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 50%;
      overflow: hidden;
      &:has(img){
        width: 34px;
        height: 34px;
        min-width: 34px;
        min-height: 34px;
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
    .user_name {
      min-width: fit-content;

      .circle-container {
        display: flex;
        align-items: center;
      }
      
      .circle {
        width: 20px;
        height: 20px; 
        border-radius: 50%; 
        margin-left: 10px;
        background-color: #333; 
        color: white; 
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px; 
        margin-right: 5px; 
      }
      

      p {
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      @media (max-width:1200px) {
        display: none;
      }
    }
    svg.arrow{
      width: 20px;
      height: 20px;
      @media (max-width:1200px) {
        display: none;
      }
    }
  }
  .header_top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-right: 3%;
    cursor: pointer;

    .use_image {
      img {
        width: 24px;
        height: 24px;
      }
    }
    .user_name {
      p {
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .logout_main_box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;

    .logout_bg {
      width: 100%;
      height: 100vh;
      position: fixed;
      //   border: 1px solid red;
      left: 0px;
      top: 0px;
      //   background-color: red;
      cursor: pointer;
    }
    .logout_option {
      position: relative;
      margin-top: 10px;
      min-width: 227px;
      border-radius: 10px;
      border: 1px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      box-shadow: 0px 4px 4px 0px #00000040;
      padding: 15px 15px 15px 20px;
      z-index: 9999;
      ul {
        li {
          padding: 5px;
          cursor: pointer;
          &:hover {
            color: #0085ff !important;
            a {
              color: #0085ff !important;
            }
          }
          a {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
          }

          p {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
