@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.events_page_loading {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
}

.event_schedule_empty_data {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.display_none {
  display: none !important;
}

.pending {
  background-color: #428af5;
}

.accepted {
  background-color: #00d16c;
}

.declined {
  background-color: #ff3c3c;
}

.rescheduled {
  background-color: #ff9900;
}

.cancelled {
  background-color: red;
}

.pending_color {
  color: #428af5;
}

.accepted_color {
  color: #00d16c;
}

.declined_color {
  color: #ff3c3c;
}

.rescheduled-color {
  color: #ff9900;
}

.cancelled_color {
  color: red;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper::-webkit-scrollbar {
  width: 10px;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper::-webkit-scrollbar-track {
  /* border-radius: 8px; */
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: lightgray;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper::-webkit-scrollbar-thumb:hover {
  background: gray;
}
