.exhibitors-list-conatiner {
  width: 100%;
  margin-top: 30px;
  .exhibitors-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .exhibitor-list-item {
      padding: 10px;
      width: 200px;
      min-height: 170px;
      max-height: 250px;
      border: 1px solid lightgray;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-right: 10px;
      //   cursor: c;
      cursor: auto;

      .top-logo {
        margin-top: 15px;
        height: 50px;
        display: flex;
        // align-items: center;
        justify-content: center;
        .exhibitor_demo_logo {
          svg {
            width: 100%;
            height: 50px;
            color: lightgray !important;
          }
        }
        img {
          max-height: 50px;
          //   width: 100%;
          //   object-fit: cover;
        }
      }

      .head_title {
        margin-top: 20px;
        p {
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 500;
          color: black;
        }
      }
      .head_link {
        a {
          color: gray;
          font-size: 12px;
          &:hover {
            color: blue;
          }
        }
      }
      .info_text {
        margin-top: 20px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: black;
      }
    }
  }
}
.exhibitors-list-conatiner{
  .new-exhibitors-list{
    flex-wrap: wrap;
    gap: 26px;

    .exhibitor-list-item{
      width: calc(33.33% - 18px);
      min-height: fit-content;
      max-height: fit-content;
      @media (max-width:1200px) {
        width: calc(50% - 13px); 
      }
      @media (max-width:576px) {
        width: 100%; 
      }
      margin: 0;
      padding: 25px 35px;    
      border-radius: 10px;
      border: 0.5px solid #d3d3d3;
      transition: 0.3s ease;
      &:hover{
        border: 1px solid rgba(0, 179, 255, 1);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      }
      .top-logo{
        margin-top: 0;
        margin-bottom: 30px;
        width: 100%;
        height: 235px;
        max-height: 235px;
        min-height: 235px;
        img{
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        .exhibitor_demo_logo{
          width: 100%;
          height: 100%;
          object-fit: contain;
          img,svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        
        }
      }
      .head_title{
        p{
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          margin-bottom: 30px;
          }
      }
      .info_text{
        text-align: center;
        margin-bottom: 20px;
        p{
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: rgba(39, 39, 39, 1);
        }
      }
      .head_link{
        text-align: center;
        a{
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: center;
          color: rgba(53, 162, 210, 1);
          &:hover{
            color: rgba(53, 162, 210, 1);
          }
        }
      }
    }
  }
}